'use client'

import { useTranslations } from 'next-intl'

import { IconLanguage, Menu } from '@fortum/elemental-ui'

import { useRouter } from '@/i18n/navigation'
import { Icon } from '@/shared/components/Icon'
import { allCountryConfigs, getCountryConfig } from '@/shared/countryConfig'
import { browserEnvs } from '@/shared/envs'
import { useTheme } from '@/shared/hooks/useTheme'
import type { localeConfig } from '@/shared/locale'
import { getAvailableLocales, getDefaultLocale, useLocale } from '@/shared/locale'

type AllowedCountry = 'FI' | 'SE'

/**
 * Footer country links menu.
 */
export const CountryMenu = () => {
  const theme = useTheme()
  const t = useTranslations('footer')
  const router = useRouter()
  const currentLocale = useLocale()
  const currentCountry = browserEnvs.NEXT_PUBLIC_COUNTRY

  // Only enable the menu for Finland
  if (currentCountry !== 'FI') {
    return null
  }

  // Explicitly allow only Sweden (SE) and Finland (FI)
  const allowedCountries: AllowedCountry[] = ['FI', 'SE']

  const items = allowedCountries.flatMap((countryCode) => {
    const availableLocales = getAvailableLocales(countryCode)
    const countryConfig = allCountryConfigs[countryCode]

    return availableLocales.map((locale) => ({
      name: `${countryConfig.countryName} - ${locale.toUpperCase()}`,
      value: `${countryCode}-${locale}`,
    }))
  })

  const currentValue = `${currentCountry}-${currentLocale}`
  const selected = items.find((item) => item.value === currentValue)

  const handleSelect = (value: string) => {
    if (typeof value === 'string' && value !== selected?.value) {
      // Extract country and locale from the selected value
      const [newCountry, newLocale] = value.split('-')
      const selectedCountryConfig = getCountryConfig(newCountry as keyof typeof localeConfig)
      const defaultLocale = getDefaultLocale(newCountry as keyof typeof localeConfig)

      // Check if the country has changed
      if (newCountry !== currentCountry) {
        const isDifferentLocale = newLocale !== defaultLocale

        // Build the new path based on the selected country and locale eg. `/se/el`
        const newPath = isDifferentLocale
          ? `${selectedCountryConfig.basePath}/${newLocale}`
          : selectedCountryConfig.basePath

        // Perform a full navigation to the new country path
        window.location.assign(newPath)
      } else {
        // If the country hasn't changed, only update the locale without full reload
        router.push('/', { locale: newLocale })
      }
    }
  }

  return (
    <Menu
      icon={<Icon icon={IconLanguage} />}
      aria-label={t('countryMenuLabel')}
      onSelect={handleSelect}
      selected={selected?.value}
      items={items}
      label={selected?.name ?? t('countryMenuLabel')}
      variant="condensed"
      color={theme.colors.textLightOnDark}
      dropdownDirection="up"
      data-testid="footer-country-menu"
    />
  )
}
