import { createNavigation } from 'next-intl/navigation'
import type { ComponentPropsWithoutRef, Ref } from 'react'

import { routes } from '@/shared/routes'
import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'

import { routing } from './routing'

// Lightweight wrappers around Next.js' navigation
// APIs that consider the routing configuration
export const {
  Link: NextIntlLink,
  useRouter: useNextIntlRouter,
  usePathname: useNextIntlPathname,
  getPathname: nextIntlGetPathname,
  redirect: nextIntlRedirect,
} = createNavigation(routing)

type NextIntlLinkType = typeof NextIntlLink
type NextLinkHref = ComponentPropsWithoutRef<NextIntlLinkType>['href']

export type LinkProps = Omit<ComponentPropsWithoutRef<NextIntlLinkType>, 'href' | 'ref'> & {
  href?: string | NextLinkHref
  ref?: Ref<HTMLAnchorElement>
}

export const isExternal = (href: string) => {
  try {
    return href.match(/https?:\/\/\S*/g) != null
  } catch (error) {
    logError(new UiError(error))
    // Treating every unknown case as external
    return true
  }
}

/**
 * Wrapped version of `next/link` that automatically handles locale prefixing and makes the `href` prop optional.
 */
export const Link = ({ href: hrefProp = '', scroll, ...props }: LinkProps) => {
  if (typeof hrefProp === 'string' && isExternal(hrefProp)) {
    return <a href={hrefProp} {...props} />
  }

  const href =
    hrefProp === 'string'
      ? { pathname: routes.GENERIC, params: { slug: hrefProp } }
      : (hrefProp as NextLinkHref)

  return <NextIntlLink href={href} scroll={scroll} {...props} />
}

/**
 * Normalize a URL by removing trailing slashes.
 */
export const normalizeUrl = (url: string) => {
  return url.endsWith('/') && url !== '/' ? url.slice(0, -1) : url
}

/**
 * Wrapped version of `useRouter` that automatically handles locale prefixing.
 */
export const useRouter = useNextIntlRouter

/**
 * Wrapped version of `usePathname` that doesn't include the locale prefix.
 */
export const usePathname = useNextIntlPathname

export const getPathname = nextIntlGetPathname

export const redirect = nextIntlRedirect
