'use client'

import { usePathname } from '@/i18n/navigation'
import { routes } from '@/shared/routes'

import { Footer } from './Footer'
import { useMainLayoutContent } from '../../providers/mainLayoutContent'

export const FooterConnector = () => {
  const { footer } = useMainLayoutContent()

  const pathname = usePathname()

  if (!footer) {
    return null
  }

  const isBusiness = pathname.includes(routes.ENTERPRISE_HOME)
  // Select the correct footer based on the URL
  const selectedFooter = isBusiness ? footer.enterprise : footer.private

  if (!selectedFooter) {
    return null
  }

  return <Footer {...selectedFooter} />
}
