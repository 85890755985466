import { defineRouting } from 'next-intl/routing'

import { browserEnvs } from '@/shared/envs'
import { getAvailableLocales, getDefaultLocale } from '@/shared/locale'
import { pathnames } from '@/shared/routes'

const country = browserEnvs.NEXT_PUBLIC_COUNTRY

export const routing = defineRouting({
  defaultLocale: getDefaultLocale(country),
  locales: getAvailableLocales(country),
  localePrefix: 'as-needed',
  localeDetection: false,
  pathnames,
})
