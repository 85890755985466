import type { Locale } from 'next-intl'

export const LOGGED_IN_ROOT_ROUTE = '/my'
export const LOGGED_IN_ROOT_ROUTE_INTL = {
  en: '/loggedin',
  sv: '/inloggad',
  fi: '/kirjautunut',
  nb: '/loggedin',
} as const

export type LoggedInRoute = (typeof loggedInRoutes)[keyof typeof loggedInRoutes]
export type PrivateCheckoutRoute = (typeof privateCheckoutRouts)[keyof typeof privateCheckoutRouts]
export type EnterpriseCheckoutRoute =
  (typeof enterpriseCheckoutRouts)[keyof typeof enterpriseCheckoutRouts]

export type AppRoute = (typeof routes)[keyof typeof routes]
export type GeneratedPathnames = Record<AppRoute, Partial<Record<Locale, string>> | string>

const loggedInRoutes = {
  LOGGED_IN_ROOT_ROUTE,
  LOGGED_IN_OVERVIEW: `${LOGGED_IN_ROOT_ROUTE}/overview`,
  LOGGED_IN_CONTRACTS: `${LOGGED_IN_ROOT_ROUTE}/contracts`,
  LOGGED_IN_ELECTRICITY: `${LOGGED_IN_ROOT_ROUTE}/electricity`,
  LOGGED_IN_INVOICES: `${LOGGED_IN_ROOT_ROUTE}/invoices`,
  LOGGED_IN_SETTINGS: `${LOGGED_IN_ROOT_ROUTE}/settings`,
} as const

const privateCheckoutRouts = {
  THANK_YOU: '/thank-you',
  UPGRADE_FLOW: '/upgrade-flow',
  CHECKOUT: '/checkout',
} as const

const enterpriseCheckoutRouts = {
  ENTERPRISE_THANK_YOU: '/enterprise/thank-you',
  ENTERPRISE_UPGRADE_FLOW: '/enterprise/upgrade-flow',
  ENTERPRISE_CHECKOUT: '/enterprise/checkout',
} as const

export const routes = {
  ...loggedInRoutes,
  ...privateCheckoutRouts,
  ...enterpriseCheckoutRouts,
  HOME: '/',
  BLOG: '/blog',
  BLOG_CATEGORY: '/blog/[slug]/[page]',
  BLOG_ARTICLE: '/blog/[slug]',
  FAQ: '/faq',
  SERVICE_BREAK: '/service-break/[client]',
  LOG_IN: '/login',

  ENTERPRISE_HOME: '/enterprise',
  ENTERPRISE_SLUG: '/enterprise/[...slug]',
  ENTERPRISE_BLOG: '/enterprise/blog',
  ENTERPRISE_BLOG_CATEGORY: '/enterprise/blog/[slug]/[page]',
  ENTERPRISE_BLOG_ARTICLE: '/enterprise/blog/[slug]',
  ENTERPRISE_FAQ: '/enterprise/faq',

  LOGGED_IN_OVERVIEW: `${LOGGED_IN_ROOT_ROUTE}/overview`,
  LOGGED_IN_CONTRACTS: `${LOGGED_IN_ROOT_ROUTE}/contracts`,
  LOGGED_IN_ELECTRICITY: `${LOGGED_IN_ROOT_ROUTE}/electricity`,
  LOGGED_IN_INVOICES: `${LOGGED_IN_ROOT_ROUTE}/invoices`,
  LOGGED_IN_SETTINGS: `${LOGGED_IN_ROOT_ROUTE}/settings`,

  QUIZ_ROOT: '/quiz',
  QUIZ: '/quiz/[slug]',
  QUIZ_RESULT: '/quiz/result/[id]',

  GENERIC: '/[slug]',
} as const

export type StaticRoute = Exclude<
  AppRoute,
  | '/blog/[slug]/[page]'
  | '/blog/[slug]'
  | '/service-break/[client]'
  | '/enterprise/[...slug]'
  | '/enterprise/blog/[slug]/[page]'
  | '/enterprise/blog/[slug]'
  | '/quiz/[slug]'
  | '/quiz/result/[id]'
  | '/[slug]'
>

const ENTERPRISE_HOME_INTL = {
  en: routes.ENTERPRISE_HOME,
  sv: `${routes.HOME}foretag`,
  fi: `${routes.HOME}yrityksille`,
  nb: `${routes.HOME}bedrift`,
}

export const pathnames: GeneratedPathnames = {
  // B2C routes
  [routes.HOME]: routes.HOME,
  [routes.UPGRADE_FLOW]: {
    en: routes.UPGRADE_FLOW,
    sv: `${routes.HOME}anpassa-elavtal`,
    fi: `${routes.HOME}muokkaa-sahkosopimusta`,
    nb: `${routes.HOME}tilpass-stromavtale`,
  },
  [routes.CHECKOUT]: {
    en: routes.CHECKOUT,
    sv: `${routes.HOME}bestallning`,
    fi: `${routes.HOME}tilaus`,
    nb: `${routes.HOME}bestill`,
  },
  [routes.THANK_YOU]: {
    en: routes.THANK_YOU,
    sv: `${routes.HOME}tack`,
    fi: `${routes.HOME}kiitos`,
    nb: `${routes.HOME}takk`,
  },
  [routes.BLOG]: {
    en: `${routes.HOME}advice/blog`,
    sv: `${routes.HOME}radgivning/blogg`,
    fi: `${routes.HOME}vinkkeja/blogi`,
    nb: `${routes.HOME}guider/blogg`,
  },
  [routes.BLOG_CATEGORY]: {
    en: `${routes.HOME}advice/blog/[slug]/[page]`,
    sv: `${routes.HOME}radgivning/blogg/[slug]/[page]`,
    fi: `${routes.HOME}vinkkeja/blogi/[slug]/[page]`,
    nb: `${routes.HOME}guider/blogg/[slug]/[page]`,
  },
  [routes.BLOG_ARTICLE]: {
    en: `${routes.HOME}advice/blog/[slug]`,
    sv: `${routes.HOME}radgivning/blogg/[slug]`,
    fi: `${routes.HOME}vinkkeja/blogi/[slug]`,
    nb: `${routes.HOME}guider/blogg/[slug]`,
  },
  [routes.FAQ]: {
    en: `${routes.HOME}support/faq`,
    sv: `${routes.HOME}kundservice/fragor-och-svar`,
    fi: `${routes.HOME}asiakaspalvelu/usein-kysytyt-kysymykset`,
    nb: `${routes.HOME}kundeservice/ofte-stilte-sporsmal`,
  },
  [routes.LOG_IN]: {
    en: routes.LOG_IN,
    sv: `${routes.HOME}loggain`,
    nb: routes.LOG_IN,
    fi: `${routes.HOME}kirjaudu`,
  },
  // Enterprise routes
  [routes.ENTERPRISE_HOME]: ENTERPRISE_HOME_INTL,
  [routes.ENTERPRISE_SLUG]: {
    en: routes.ENTERPRISE_SLUG,
    sv: `${ENTERPRISE_HOME_INTL.sv}/[...slug]`,
    fi: `${ENTERPRISE_HOME_INTL.fi}/[...slug]`,
    nb: `${ENTERPRISE_HOME_INTL.nb}/[...slug]`,
  },
  [routes.ENTERPRISE_UPGRADE_FLOW]: {
    en: routes.ENTERPRISE_UPGRADE_FLOW,
    sv: `${ENTERPRISE_HOME_INTL.sv}/anpassa-elavtal`,
    fi: `${ENTERPRISE_HOME_INTL.fi}/muokkaa-sahkosopimusta`,
    nb: `${ENTERPRISE_HOME_INTL.nb}/tilpass-stromavtale`,
  },
  [routes.ENTERPRISE_CHECKOUT]: {
    en: routes.ENTERPRISE_CHECKOUT,
    sv: `${ENTERPRISE_HOME_INTL.sv}/bestallning`,
    fi: `${ENTERPRISE_HOME_INTL.fi}/tilaus`,
    nb: `${ENTERPRISE_HOME_INTL.nb}/bestill`,
  },
  [routes.ENTERPRISE_THANK_YOU]: {
    en: routes.ENTERPRISE_THANK_YOU,
    sv: `${ENTERPRISE_HOME_INTL.sv}/tack`,
    fi: `${ENTERPRISE_HOME_INTL.fi}/kiitos`,
    nb: `${ENTERPRISE_HOME_INTL.nb}/takk`,
  },
  [routes.ENTERPRISE_BLOG]: {
    en: `${ENTERPRISE_HOME_INTL.en}/advice/blog`,
    sv: `${ENTERPRISE_HOME_INTL.sv}/radgivning/blogg`,
    fi: `${ENTERPRISE_HOME_INTL.fi}/vinkkeja/blogi`,
    nb: `${ENTERPRISE_HOME_INTL.nb}/guider/blogg`,
  },
  [routes.ENTERPRISE_BLOG_CATEGORY]: {
    en: `${ENTERPRISE_HOME_INTL.en}/advice/blog/[slug]/[page]`,
    sv: `${ENTERPRISE_HOME_INTL.sv}/radgivning/blogg/[slug]/[page]`,
    fi: `${ENTERPRISE_HOME_INTL.fi}/vinkkeja/blogi/[slug]/[page]`,
    nb: `${ENTERPRISE_HOME_INTL.nb}/guider/blogg/[slug]/[page]`,
  },
  [routes.ENTERPRISE_BLOG_ARTICLE]: {
    en: `${ENTERPRISE_HOME_INTL.en}/advice/blog/[slug]`,
    sv: `${ENTERPRISE_HOME_INTL.sv}/radgivning/blogg/[slug]`,
    fi: `${ENTERPRISE_HOME_INTL.fi}/vinkkeja/blogi/[slug]`,
    nb: `${ENTERPRISE_HOME_INTL.nb}/guider/blogg/[slug]`,
  },
  [routes.ENTERPRISE_FAQ]: {
    en: `${ENTERPRISE_HOME_INTL.en}/support/faq`,
    sv: `${ENTERPRISE_HOME_INTL.sv}/kundservice/fragor-och-svar`,
    fi: `${ENTERPRISE_HOME_INTL.fi}/asiakaspalvelu/usein-kysytyt-kysymykset`,
    nb: `${ENTERPRISE_HOME_INTL.nb}/kundeservice/ofte-stilte-sporsmal`,
  },
  // Logged in routes
  [routes.LOGGED_IN_ROOT_ROUTE]: LOGGED_IN_ROOT_ROUTE_INTL,
  [routes.LOGGED_IN_OVERVIEW]: {
    en: routes.LOGGED_IN_OVERVIEW,
    sv: `${LOGGED_IN_ROOT_ROUTE_INTL['sv']}/oversikt`,
    fi: `${LOGGED_IN_ROOT_ROUTE_INTL['fi']}/etusivu`,
    nb: routes.LOGGED_IN_OVERVIEW,
  },
  [routes.LOGGED_IN_ELECTRICITY]: {
    en: routes.LOGGED_IN_ELECTRICITY,
    sv: `${LOGGED_IN_ROOT_ROUTE_INTL['sv']}/el`,
    fi: `${LOGGED_IN_ROOT_ROUTE_INTL['fi']}/sahko`,
    nb: routes.LOGGED_IN_ELECTRICITY,
  },
  [routes.LOGGED_IN_CONTRACTS]: {
    en: routes.LOGGED_IN_CONTRACTS,
    sv: `${LOGGED_IN_ROOT_ROUTE_INTL['sv']}/avtal`,
    fi: `${LOGGED_IN_ROOT_ROUTE_INTL['fi']}/sopimukset`,
    nb: routes.LOGGED_IN_CONTRACTS,
  },
  [routes.LOGGED_IN_INVOICES]: {
    en: routes.LOGGED_IN_INVOICES,
    sv: `${LOGGED_IN_ROOT_ROUTE_INTL['sv']}/fakturor`,
    fi: `${LOGGED_IN_ROOT_ROUTE_INTL['fi']}/laskut`,
    nb: routes.LOGGED_IN_INVOICES,
  },
  [routes.LOGGED_IN_SETTINGS]: {
    en: routes.LOGGED_IN_SETTINGS,
    sv: `${LOGGED_IN_ROOT_ROUTE_INTL['sv']}/installningar`,
    fi: `${LOGGED_IN_ROOT_ROUTE_INTL['fi']}/asetukset`,
    nb: routes.LOGGED_IN_SETTINGS,
  },
  [routes.SERVICE_BREAK]: {
    en: routes.SERVICE_BREAK,
    sv: '/service-avbrott/[client]',
    fi: routes.SERVICE_BREAK,
    nb: routes.SERVICE_BREAK,
  },

  // TODO: Add translations for quiz
  [routes.QUIZ_ROOT]: {
    en: routes.QUIZ_ROOT,
    sv: routes.QUIZ_ROOT,
    nb: routes.QUIZ_ROOT,
    fi: routes.QUIZ_ROOT,
  },

  [routes.QUIZ]: {
    en: routes.QUIZ,
    sv: routes.QUIZ,
    nb: routes.QUIZ,
    fi: routes.QUIZ,
  },
  [routes.QUIZ_RESULT]: {
    en: routes.QUIZ_RESULT,
    sv: '/quiz/resultat/[id]',
    nb: routes.QUIZ_RESULT,
    fi: routes.QUIZ_RESULT,
  },
  [routes.GENERIC]: routes.GENERIC,
}

export type ServiceBreakClientSearchParam =
  (typeof serviceBreakClientSearchParam)[keyof typeof serviceBreakClientSearchParam]

export const serviceBreakClientSearchParam = {
  LOG_IN: 'log-in',
  PURCHASE_FLOW: 'purchase-flow',
} as const
