import { Box, ContentText, FortumLogo, spacing } from '@fortum/elemental-ui'

import { MobileAppDownload } from '@/shared/components/MobileAppDownload'
import type { FooterEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import { FooterColumn } from './FooterColumn'
import { QuickLinks } from './QuickLinks'
import { SeparatorRow } from './SeparatorRow'

type Props = Pick<FooterEntry, 'appLinks' | 'columnsCollection'>

/**
 * First row of the footer component.
 */
export const FirstRow = (props: Props) => {
  const theme = useTheme()

  const { appLinks, columnsCollection } = props

  return (
    <>
      <Box
        pt={spacing.s}
        pb={{ default: 0, s: spacing.s }}
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        columnGap={{ default: '0.75rem', s: spacing.xxs, l: spacing.xs }}
        rowGap={{ default: 0, s: spacing.xxs }}
        data-testid="first-row"
      >
        <Box
          gridColumn={{ default: 'span 12', s: 'span 6', l: 'span 3' }}
          display="flex"
          flexDirection="column"
          alignItems={{ default: 'center', s: 'flex-start' }}
          gap={{ default: spacing.xs, m: spacing.s }}
          pb={spacing.xxs}
        >
          <FortumLogo p={0} color={theme.colors.textLightOnDark} />
          {appLinks && <MobileAppDownload {...appLinks} />}
        </Box>

        {columnsCollection?.items.map((column, index) => {
          if (!column) {
            return <Box key={index} gridColumn={{ default: 'span 12', s: 'span 6', l: 'span 3' }} />
          }

          const linksCollection = column.linksCollection?.items || []

          const dataTestId =
            linksCollection && linksCollection.length > 0 ? 'quick-links' : 'free-text'

          const panelId = linksCollection?.length > 0 ? 'footer-quick-links' : 'footer-free-text'

          const filteredLinks = linksCollection.filter((link) => link !== null)

          return (
            <Box
              key={index}
              gridColumn={{ default: 'span 12', s: 'span 6', l: 'span 3' }}
              data-testid={`footer-${dataTestId}`}
            >
              <FooterColumn title={column.title} panelId={panelId}>
                {filteredLinks.length > 0 ? (
                  <QuickLinks quickLinks={filteredLinks} />
                ) : (
                  <ContentText color={theme.colors.textLightOnDark} data-testid={dataTestId}>
                    {column.freeText}
                  </ContentText>
                )}
              </FooterColumn>
            </Box>
          )
        })}
      </Box>
      <SeparatorRow />
    </>
  )
}
