'use client'

import styled from 'styled-components'

import { boxPropExclusions } from '@fortum/elemental-ui'

import type { LinkProps } from '@/i18n/navigation'
import { Link } from '@/i18n/navigation'
import { useTheme } from '@/shared/hooks/useTheme'

import { type LinkStyleProps, linkMixins } from './styled'

export type StyledLinkProps = LinkProps & LinkStyleProps

/**
 * Styled link component that functions as a next-intl link and looks like an Elemental link.
 *
 * Note: We cannot just wrap the Elemental link and use the passHref and legacyBehavior props, as those are not compatible with the next-intl link.
 */
export const StyledLink = ({
  size = 'm',
  color,
  noUnderline,
  noUnderlineHover,
  isHoverDisabled,
  ...restProps
}: StyledLinkProps) => {
  const theme = useTheme()

  return (
    <StyledLinkInternal
      fontSize={theme.contentText.web.fontSizes[size]}
      color={color}
      noUnderline={noUnderline || noUnderlineHover}
      noUnderlineHover={noUnderlineHover}
      isHoverDisabled={isHoverDisabled}
      {...restProps}
    />
  )
}

const shouldForwardProp = (prop: string): boolean => {
  const customProps = ['color', 'noUnderline', 'noUnderlineHover', 'hover', 'isHoverDisabled']
  const propExclusions = new Set([...customProps, ...boxPropExclusions])
  return !propExclusions.has(prop)
}

const StyledLinkInternal = styled(Link).withConfig({ shouldForwardProp })<LinkStyleProps>`
  ${linkMixins}
`
