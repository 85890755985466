import { getPathname } from '@/i18n/navigation'

import { countryConfig } from '../countryConfig'
import { browserEnvs, serverEnvs } from '../envs'
import type { Locale } from '../locale'
import { getDefaultLocale, getPaginatedPath } from '../locale'
import { routes } from '../routes'
import type {
  AnyEntry,
  ArticleEntry,
  EnterprisePageEntry,
  LinkEntry,
  PageEntry,
  PaginatedParams,
} from './types'
import { isNotNullOrUndefined } from '../utils/isNotNullOrUndefined'
import { addHttpsProtocol } from '../utils/url'
import { isArticleEntry, isTypeEnterprisePageEntry } from './types/contentTypeGuards'

export type Page = Pick<
  PageEntry | EnterprisePageEntry,
  'slug' | 'title' | 'breadcrumb' | '__typename' | 'parentPage'
>
export type PathParams =
  | Page
  | Pick<ArticleEntry, 'slug' | 'title' | 'breadcrumb' | '__typename' | 'customerType'>

export const getPageSlugParams = (page: PathParams): string[] => {
  // Articles do not have parent page
  if (page.__typename === 'Article') {
    return [page.slug].filter(Boolean)
  }

  const parents = getParentPages(page)

  const stripLeadingSlash = (slug: string) => slug.replace(/^\/+/, '')

  return parents
    .map((parent) => parent.slug)
    .concat(page.slug)
    .filter(Boolean)
    .map(stripLeadingSlash)
    .filter(Boolean)
}

/**
 * Gets the full path of a page or article, including its parent pages. Including segment structure.
 *
 * Should be used for any navigation purposes.
 *
 * For example for enterprisePage will add prefix /enterprise.
 * For article will append custom localized prefix.
 */
export const getPageRoutePath = (page: PathParams, locale?: Locale): string => {
  const slugs = getPageSlugParams(page)
  const localeValue = locale ?? getDefaultLocale(browserEnvs.NEXT_PUBLIC_COUNTRY)

  if (isArticleEntry(page)) {
    return getPathname({
      locale: localeValue,
      href: {
        pathname: page.customerType?.includes('Private')
          ? routes.BLOG_ARTICLE
          : routes.ENTERPRISE_BLOG_ARTICLE,
        params: {
          slug: page.slug,
        },
      },
      // Typings for next-intl params are not working as expected, we use intentionally "any" casting to skip TS check here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any)
  }

  const homePrefix = getPathname({
    href: isTypeEnterprisePageEntry(page) ? routes.ENTERPRISE_HOME : routes.HOME,
    locale: localeValue,
  })

  if (homePrefix === '/') {
    // Not prefixed pages eg.: b2c
    return `/${slugs.join('/')}`
  }

  // if length of slugs is more than zero add "/" on the prefix end
  const root = slugs?.length > 0 ? `${homePrefix}/` : homePrefix

  return `${root}${slugs.join('/')}`
}

/**
 * Gets the full path of a page or article, including its parent pages.
 *
 * Should be used to identify pathname with the Contentful data
 *
 * For example: `/products-and-services/addons/ev-charging`
 */
export const getPagePath = (page: PathParams): string => {
  const slugs = getPageSlugParams(page)

  return `/` + slugs.join('/')
}

/**
 * Gets the full URL of a page or article, including the base URL, locale prefix and path.
 * Should be used in scope of next-intl
 */
export const getPageUrl = (
  baseUrl: string,
  page: PathParams,
  locale: Locale | undefined,
  paginatedParams?: PaginatedParams,
) => {
  const url = `${baseUrl}${getPageRoutePath(page, locale as Locale)}${getPaginatedPath(paginatedParams)}`

  return url.replace(/\/$/, '') // Remove trailing slash
}

/**
 * Recursively gets all parent pages of a page or article as an array.
 * The first item is the root parent, the last item is the direct parent.
 */
export const getParentPages = (page: Page, parents: PathParams[] = []): PathParams[] => {
  const parentPage = page?.parentPage

  if (!parentPage) {
    return parents.toReversed()
  }

  parents.push(parentPage)
  return getParentPages(parentPage, parents)
}

type LinkEntryParams = Pick<LinkEntry, 'page' | 'url'>

export const getLinkEntryUrl = ({ page, url }: LinkEntryParams): string => {
  if (page) {
    return getPageRoutePath(page)
  }
  if (url) {
    return addHttpsProtocol(url)
  }
  // Return # to avoid empty passing empty string as href, empty href is treated as same page url and click trigger page reload
  return '#'
}

export const getAssetUrl = (
  asset: { url?: string | null } | undefined | null,
): string | undefined => {
  const url = asset?.url
  return url ? resolveAssetUrl(url) : undefined
}

export const resolveAssetUrl = (url: string): string =>
  url.startsWith('//') ? `https:${url}` : url

export const orderById = <T extends AnyEntry>(
  ids: string[],
  data?: (T | null | undefined)[],
): T[] => {
  return ids.map((id) => data?.find((d) => d?.sys?.id === id))?.filter(isNotNullOrUndefined)
}

export const parseContentfulVariables = (
  str: string,
  variables: { [key: string]: string },
): string => {
  let result = str

  for (const key in variables) {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result.replace(regex, variables[key])
  }

  return result
}

export const resolveDrupalLink = (url: string): string => {
  const { APP_ENV } = serverEnvs
  if (!url) {
    return '#'
  }

  try {
    const parsedUrl = new URL(url)
    const allowedHosts = ['fortum.com', 'www.fortum.com']

    // Only process URLs if the host is allowed and they don't already contain basePath
    if (allowedHosts.includes(parsedUrl.host) && !url.includes(countryConfig.basePath)) {
      const envPrefix = APP_ENV === 'stg' ? 'staging.' : APP_ENV === 'dev' ? 'dev.' : ''
      // Use a regex to match the beginning of the URL with protocol and optional "www."
      const domainRegex = new RegExp(`^https?:\\/\\/(www\\.)?fortum.com`)
      return url.replace(domainRegex, `https://${envPrefix}fortum.com`)
    }
  } catch {
    // If URL parsing fails, return a fallback
    return '#'
  }
  return url
}

export const draftLocals = (locale?: Locale) => {
  const pathLocale = locale ? `/${locale}` : ''
  const countryCode =
    locale === 'sv' ? 'SE' : locale === 'en' ? 'GB' : browserEnvs.NEXT_PUBLIC_COUNTRY

  return { pathLocale, countryCode }
}
